@import 'tailwindcss';

@theme {
  /* Text sizes */
  --text-xs: 12px;
  --text-sm: 14px;
  --text-base: 16px;
  --text-lg: 20px;
  --text-xl: 24px;
  --text-2xl: 32px;
  --text-3xl: 48px;
  --text-4xl: 64px;
  --text-5xl: 128px;

  /* Spacing */
  --spacing: 1px;

  /* Primary colors */
  --color-primary-50: #f8fdff;
  --color-primary-100: #e0f4fe;
  --color-primary-200: #b9ebfe;
  --color-primary-300: #7cdbfd;
  --color-primary-400: #36cbfa;
  --color-primary-500: #0cb5eb;
  --color-primary-600: #009cd7;
  --color-primary-700: #0174a3;
  --color-primary-800: #066186;
  --color-primary-900: #153e53;
  --color-primary-950: #113345;

  /* Secondary colors */
  --color-secondary-50: #f9fafb;
  --color-secondary-100: #eff2f5;
  --color-secondary-200: #c8d4d9;
  --color-secondary-300: #b0bec5;
  --color-secondary-400: #78909c;
  --color-secondary-500: #546e7a;
  --color-secondary-600: #4c616e;
  --color-secondary-700: #42525c;
  --color-secondary-800: #3c474e;
  --color-secondary-900: #353d44;

  /* Grey colors */
  --color-grey-50: #f6f6f6;
  --color-grey-100: #e7e7e7;
  --color-grey-200: #d8d8d8;
  --color-grey-300: #b2b2b2;
  --color-grey-400: #8b8b8b;
  --color-grey-500: #6d6d6d;
  --color-grey-600: #5d5d5d;
  --color-grey-700: #4f4f4f;
  --color-grey-800: #454545;
  --color-grey-900: #3e3e3e;

  /* Error colors */
  --color-error-50: #fef3f2;
  --color-error-100: #ffe3e1;
  --color-error-200: #ffccc8;
  --color-error-300: #ffa8a2;
  --color-error-400: #fc776d;
  --color-error-500: #f44336;
  --color-error-600: #e22d20;
  --color-error-700: #be2217;
  --color-error-800: #9d2017;
  --color-error-900: #82211a;

  /* Success colors */
  --color-success-50: #edfcf5;
  --color-success-100: #d3f8e4;
  --color-success-200: #aaf0ce;
  --color-success-300: #72e3b3;
  --color-success-400: #3acd93;
  --color-success-500: #19c98a;
  --color-success-600: #0a9163;
  --color-success-700: #087452;
  --color-success-800: #095c43;
  --color-success-900: #094b38;

  /* Warning colors */
  --color-warning-50: #fffbeb;
  --color-warning-100: #fff4c6;
  --color-warning-200: #ffe788;
  --color-warning-300: #ffd95c;
  --color-warning-400: #ffc120;
  --color-warning-500: #f99f07;
  --color-warning-600: #dd7702;
  --color-warning-700: #b75306;
  --color-warning-800: #943f0c;
  --color-warning-900: #7a350d;

  /* Keppel colors */
  --color-keppel-100: #d8f5f0;
  --color-keppel-200: #a8ebe1;
  --color-keppel-300: #7edcd0;
  --color-keppel-500: #25aaa2;
  --color-keppel-600: #1b8682;
  --color-keppel-700: #196c69;

  /* Base colors */
  --color-overlay: rgba(0, 0, 0, 0.5);
  --color-white: #ffffff;
  --color-black: #000000;

  /* Shadows */
  --shadow-xs: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  --shadow-sm: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  --shadow-md: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  --shadow-lg: 0px -8px 24.6px -4px rgba(16, 24, 40, 0.05),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  --shadow-xl: 0px -1px 11.8px 0px rgba(16, 24, 40, 0.07),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  --shadow-2xl: 0px -2px 30.8px 0px rgba(16, 24, 40, 0.08),
    0px 24px 48px -12px rgba(16, 24, 40, 0.18);
  --shadow-3xl: 0px -4px 64px 0px rgba(16, 24, 40, 0.1),
    0px 32px 64px -12px rgba(16, 24, 40, 0.14);
}

@layer components {
  .typography {
    a {
      @apply text-primary-500 underline;
    }

    ol,
    ul {
      @apply list-none ml-24 mb-24 mt-24;

      li::before {
        @apply text-primary-600 font-bold inline-block w-[1em] -ml-[1em] content-['•'];
      }
    }
  }
}

/* Remove outline from all inputs */
input,
textarea,
select,
button,
[contenteditable='true'] {
  outline: none;
}

/* Ensure focus states are still accessible for keyboard users */
input:focus-visible,
textarea:focus-visible,
select:focus-visible,
button:focus-visible,
[contenteditable='true']:focus-visible {
  outline: none;
}

fc-chat-footer {
  .ql-container.ql-snow {
    border: none;
  }

  .ql-toolbar.ql-snow {
    border: none;
  }
}

.force-hidden {
  display: none !important;
}
