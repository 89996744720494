@use 'var' as *;
:root {
  --mat-menu-item-label-text-color: var(--grey-900);
}

html .mat-mdc-slide-toggle {
  --mat-checkbox-label-text-color: var(--grey-500);
}

.mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: white;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}

// used in vehicle detail component for menu
.group-list-menu {
  .divider {
    color: $grey-100;
  }

  .subtitle {
    margin: 20px;
  }

  .mat-mdc-menu-item {
    .group-name {
      margin-right: 45px;
    }

    .group-checked {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}

// todo temporary fix for menu item text until we create a new component
span.mat-mdc-menu-item-text {
  display: flex;
  align-items: center;
  gap: 8px;
}

.conditions-menu.mat-mdc-menu-panel {
  width: 650px;
  max-width: 650px;
}
